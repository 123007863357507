import React, {FC, useState} from "react";
import {injectAppStore} from "../../common/app.store.consumer";
import {AppStore} from "../../stores/app.store";
import {useCSVReader, lightenDarkenColor, useCSVDownloader} from "react-papaparse";
import {Props} from "react-papaparse/dist/useCSVReader";
import {Props as RemoveProps} from "react-papaparse/dist/Remove";
import {Popup} from "devextreme-react/popup";
import {ParseResult} from "papaparse";
import {IParticipantImportDto} from "../../stores/feedback.providers.store";
import {confirm} from "devextreme/ui/dialog";
import {TeamParticipantValidationResponse} from "../../common/webapicall";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Trans} from "react-i18next";

interface ProgressBarProps {
	style?: any;
	className?: string;
	percentage?: number;
	display?: string;
	isButton?: boolean;
}

interface IProps {
	appStore?: AppStore;
	teamId: string;
	successImport: () => void;
}

const DEFAULT_REMOVE_HOVER_COLOR = "#A01919";
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(DEFAULT_REMOVE_HOVER_COLOR, 40);

export const ParticipantImport: FC<IProps> = injectAppStore()(({appStore, teamId, successImport}) => {
	const {CSVReader}: {CSVReader: FC<Props<IParticipantImportDto>>} = useCSVReader();
	const {CSVDownloader, Type} = useCSVDownloader();
	const [zoneHover, setZoneHover] = useState(false);
	const [removeHoverColor, setRemoveHoverColor] = useState(DEFAULT_REMOVE_HOVER_COLOR);
	const [isErrorModelOpen, setIsErrorModelOpen] = useState(false);
	const [errors, setErrors] = useState<Papa.ParseError[]>([]);

	const validate = async (results: ParseResult<IParticipantImportDto>) => {
		const emailRegex = new RegExp(/^[\d\w._-]+@[\d\w._-]+\.[\w]+$/i);
		let hasTeamLeader = false;

		if ((results.data?.length ?? 0) === 0) {
			results.errors.push({
				code: "InvalidQuotes",
				type: "Quotes",
				message: appStore.translationStore.translate("One Team Leader must be maintained in a team while a diagnostic is in progress."),
				row: -1,
			});

			return;
		}

		const uniqueEmail = Object.groupBy(
			results.data?.filter(s => s.email),
			s => s.email,
		);

		let index = 1;
		for (const element of results.data) {
			index++;
			const errorCount = results.errors.length;
			if (!element.email) {
				results.errors.push({code: "InvalidQuotes", type: "Quotes", message: appStore.translationStore.translate("validation-email"), row: index});
			} else if (!emailRegex.test(element.email)) {
				results.errors.push({code: "InvalidQuotes", type: "Quotes", message: appStore.translationStore.translate("Invalid Email format"), row: index});
			} else if (uniqueEmail[element.email].length > 1) {
				results.errors.push({
					code: "InvalidQuotes",
					type: "Quotes",
					message: appStore.translationStore.translate("Email presents multiple times"),
					row: index,
				});
			}
			if (!element.firstName) {
				results.errors.push({
					code: "InvalidQuotes",
					type: "Quotes",
					message: appStore.translationStore.translate("First name is required"),
					row: index,
				});
			}
			if (!element.lastName) {
				results.errors.push({code: "InvalidQuotes", type: "Quotes", message: appStore.translationStore.translate("Last name is required"), row: index});
			}
			if (!element.relationship) {
				results.errors.push({
					code: "InvalidQuotes",
					type: "Quotes",
					message: appStore.translationStore.translate("Relationship is required"),
					row: index,
				});
			} else {
				if (element.relationship.toLocaleLowerCase() === "s") {
					element.relationship = "SH";
				}
				if (
					appStore.participantRoleStore.participantRolesDropdown.findIndex(s => s.value?.toLowerCase() === element.relationship.toLowerCase()) === -1
				) {
					results.errors.push({
						code: "InvalidQuotes",
						type: "Quotes",
						message: appStore.translationStore.translate("Relationship is invalid"),
						row: index,
					});
				} else {
					element.relationship = element.relationship.toUpperCase();
				}

				if (element.relationship === "TL") {
					hasTeamLeader = true;
				}
			}

			if (errorCount === results.errors.length) {
				try {
					await appStore.feedbackProvidersStore.isEmailUnique("", element.email);
				} catch (error) {
					if (error === TeamParticipantValidationResponse.EmailBelongsToAdmin) {
						results.errors.push({
							code: "InvalidQuotes",
							type: "Quotes",
							message: appStore.translationStore.translate("Admin users cannot be added to the team diagnostic"),
							row: index,
						});
					} else {
						results.errors.push({
							code: "InvalidQuotes",
							type: "Quotes",
							message: error,
							row: index,
						});
					}
				}
			}
		}

		const currentUser = results.data.find(s => s.email.toLowerCase() === appStore.userStore.email.toLowerCase());
		if (!hasTeamLeader) {
			results.errors.push({
				code: "InvalidQuotes",
				type: "Quotes",
				message: appStore.translationStore.translate("At least one team leader is required"),
				row: -1,
			});
		} else if (appStore.feedbackProvidersStore.isCurrentUserTeamLeader && !!currentUser) {
			results.errors.push({
				code: "InvalidQuotes",
				type: "Quotes",
				message: appStore.translationStore.translate("You are not allowed to delete yourself from the team."),
				row: -1,
			});
		}
	};

	const onUploadAccepted = async (results: ParseResult<IParticipantImportDto>) => {
		await validate(results);

		if (results.errors.length === 0) {
			const result = await confirm(
				appStore.translationStore.translate(
					"You are about to import {{count}} participants that will completely replace the current ones. Do you want to proceed?",
					{count: results.data.length},
				),
				appStore.translationStore.translate("Warning"),
			);

			if (result) {
				await appStore.feedbackProvidersStore.ImportParticipants(teamId, results.data);

				const currentUser = results.data.find(s => s.email.toLowerCase() === appStore.userStore.email.toLowerCase());
				if (appStore.feedbackProvidersStore.isCurrentUserTeamLeader && !!currentUser) {
					appStore.userStore.logout();
				} else {
					successImport();
				}
			}
		} else {
			setErrors(results.errors.sort(s => s.row));
			setIsErrorModelOpen(true);
		}

		setZoneHover(false);
	};

	return (
		<>
			<Popup height="auto" visible={isErrorModelOpen} onHiding={() => setIsErrorModelOpen(false)} title={appStore.translationStore.translate("Error")}>
				<div>
					<div className="import-error-list mt--mb-2">
						{errors.map((s: Papa.ParseError | Papa.ParseError[], index) => {
							if (Array.isArray(s)) {
								return s.map((error: Papa.ParseError, innerIndex) => (
									<p key={index}>
										{error.row === -1
											? appStore.translationStore.translate("The import failed due to the following error: {{message}}", {
													message: error.message,
											  })
											: appStore.translationStore.translate(
													"The import failed due to the following error in the line: {{line}}, reason: {{message}}",
													{line: error.row + 2, message: error.message},
											  )}
									</p>
								));
							} else {
								return (
									<p key={index}>
										{s.row === -1
											? appStore.translationStore.translate("The import failed due to the following error: {{message}}", {
													message: s.message,
											  })
											: appStore.translationStore.translate(
													"The import failed due to the following error in the line: {{line}}, reason: {{message}}",
													{line: s.row, message: s.message},
											  )}
									</p>
								);
							}
						})}
					</div>
					<button className="mt--btn btn-med mt--m-auto" onClick={() => setIsErrorModelOpen(false)}>
						{appStore.translationStore.translate("Close")}
					</button>
				</div>
			</Popup>
			<CSVReader
				onUploadAccepted={result => onUploadAccepted(result)}
				config={{
					header: true,
					skipEmptyLines: true,
				}}
				onDragOver={(event: DragEvent) => {
					event.preventDefault();
					setZoneHover(true);
				}}
				onDragLeave={(event: DragEvent) => {
					event.preventDefault();
					setZoneHover(false);
				}}
			>
				{({
					getRootProps,
					acceptedFile,
					ProgressBar,
					getRemoveFileProps,
					Remove,
				}: {
					getRootProps: any;
					acceptedFile: any;
					ProgressBar: FC<ProgressBarProps>;
					getRemoveFileProps: any;
					Remove: FC<RemoveProps>;
				}) => (
					<div className="drag-and-drop mt--three-quarters ">
						<div {...getRootProps()} className={`zone ${zoneHover && "zoneHover"}`}>
							{acceptedFile && (
								<>
									<div>
										<div className="info">
											{/*<span className="size">{formatFileSize(acceptedFile.size)}</span>*/}
											<span className="name">{acceptedFile.name}</span>
										</div>
										<div className="progressBar">
											<ProgressBar />
										</div>
										<div
											{...getRemoveFileProps()}
											className="remove mt--hide"
											onMouseOver={(event: Event) => {
												event.preventDefault();
												setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
											}}
											onMouseOut={(event: Event) => {
												event.preventDefault();
												setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
											}}
										>
											<Remove color={removeHoverColor} />
										</div>
									</div>
								</>
							)}
							{appStore.translationStore.translate("Drop CSV file here or click to import feedback providers")}
						</div>
					</div>
				)}
			</CSVReader>
			<div className="info">
				<FontAwesomeIcon icon={["fas", "info-circle"]} />
				<Trans i18nKey="<span>Access the </span><0><0><0><0>CSV Template</0></0></0></0><span> for managing feedback provider details.</span>">
					<CSVDownloader
						type={Type.Link}
						filename={"CSV_Template"}
						bom
						data={() => {
							const result: IParticipantImportDto[] = [
								{email: "example1@example.com", firstName: "FirstName1", lastName: "LastName1", relationship: "TL"},
								{email: "example2@example.com", firstName: "FirstName2", lastName: "LastName2", relationship: "LM"},
								{email: "example3@example.com", firstName: "FirstName3", lastName: "LastName3", relationship: "S"},
								{email: "example4@example.com", firstName: "FirstName4", lastName: "LastName4", relationship: "TM"},
							];
							return result;
						}}
					>
						<span className="mt--btn-linkalike">
							<span>
								<u></u>
							</span>
						</span>
					</CSVDownloader>
				</Trans>
			</div>
		</>
	);
});
