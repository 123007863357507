import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {confirm} from "devextreme/ui/dialog";
import React from "react";
import {RouteComponentProps, withRouter} from "react-router";
import {injectAppStore} from "../../common/app.store.consumer";
import {guard, isLoggedIn} from "../../common/guard";
import {getSurveyNavigationItems} from "../../common/navigation-items/public.submenu.items";
import {QuestionDto} from "../../common/webapicall";
import {AppStore} from "../../stores/app.store";
import PublicPageHeader from "../page-header/page.header";
import PublicSurveyHelpers from "./survey.helpers";
import SurveyNotificationBar from "./survey.notification";
import PublicSurveyStatus from "./survey.status";
import {Trans} from "react-i18next";

type PublicSurveyProps = {appStore: AppStore} & RouteComponentProps<{pSurveyId: string}, any, {scrollToSubmit: boolean}>;
interface PublicSurveyState {
	canEdit: boolean;
}

@guard(isLoggedIn)
@injectAppStore()
class PublicSurvey extends React.Component<PublicSurveyProps, PublicSurveyState> {
	private readonly DEFAULT_VALUE = 5;
	private contextStore = this.props.appStore.contextStore;
	private teamSurveyStore = this.props.appStore.teamSurveyStore;
	private submitButtonRef = React.createRef<HTMLButtonElement>();

	state: PublicSurveyState = {
		canEdit: false,
	};

	async componentDidMount() {
		await this.initializeSurvey();
		if (this.props.location.state?.scrollToSubmit) {
			this.scrollToSubmit();
		}
	}

	async componentDidUpdate(prevProps: PublicSurveyProps) {
		if (this.props.match.params.pSurveyId !== prevProps.match.params.pSurveyId) {
			await this.initializeSurvey();
		}

		if (this.teamSurveyStore.canEditSurveyForm !== this.state.canEdit) {
			this.setState({canEdit: this.teamSurveyStore.canEditSurveyForm});
		}
	}

	initializeSurvey = async () => {
		const pSurveyIdFromParam = this.props.match.params.pSurveyId;
		if (pSurveyIdFromParam) {
			this.setState({canEdit: this.teamSurveyStore.canEditSurveyForm});
			await this.teamSurveyStore.getTeamSurvey(pSurveyIdFromParam);
		}
	};

	handleRangeChange = (questionId: string, value: number) => {
		this.setReferenceWidth(questionId, value);
		this.teamSurveyStore.handleAnswerChange(questionId, false, value);
	};

	handleCheckboxChange = async (questionId: string, event: React.ChangeEvent<HTMLInputElement>) => {
		this.setReferenceWidth(event.target.name, parseInt(event.target.value, 10), event.target.checked);
		this.teamSurveyStore.handleAnswerChange(questionId, event.target.checked, null);
		await this.saveAnswer(questionId);
	};

	saveAnswer = async (questionId: string) => {
		await this.teamSurveyStore.saveAnswer(questionId);
	};

	onSubmitDiagnostic = async () => {
		let result = await confirm(
			`<div style='min-width: 360px;'>${this.props.appStore.translationStore.translate("Are you ready to submit your responses?")}</div>`,
			this.props.appStore.translationStore.translate("Submit your Meta Team diagnostic"),
		);
		if (result) {
			this.setState({canEdit: false});
			await this.teamSurveyStore.submitDiagnostic();
			await this.initializeSurvey();
			this.teamSurveyStore.canSubmitSurvey = false;

			const pSurveyIdFromParam = this.props.match.params.pSurveyId;
			await this.contextStore.loadContext(null, pSurveyIdFromParam);
		}
	};

	private extendClassName = (baseClassName: string, question: QuestionDto) => {
		let outClassName = baseClassName;
		if (question.cannotAnswer) {
			outClassName += " " + outClassName + "-cant-say";
		} else if (question.untouched) {
			outClassName += " " + outClassName + "-untouched";
		}
		return outClassName;
	};

	private scrollToSubmit = () => {
		this.submitButtonRef.current.scrollIntoView({behavior: "smooth"});
	};

	private setReferenceWidth(refName: string, value: number, checked: boolean = true): void {
		if (checked) {
			this[refName].style.width = "0";
		} else {
			this[refName].style.width = this.calculateWidthInPercentage(value);
		}
	}

	private calculateWidthInPercentage(value: number): string {
		if (value === null) return "0";
		return `${(value - 1) * (100 / 9)}%`;
	}

	render() {
		const questions = this.teamSurveyStore.questions;
		const pSurveyId = this.contextStore.contextModel.teamModel.loggedInUserParticipantSurveyId;
		const teamId = this.contextStore.contextModel.teamModel.teamId;
		const isDiagnosticSubmitted = this.contextStore.contextModel.progress.isDiagnosticSubmitted;
		const canSubmitSurvey = this.teamSurveyStore.canSubmitSurvey;
		const canAnswerWithCantSay = this.teamSurveyStore.canAnswerWithCantSay;
		const isTeamLeaderOrAdmin = this.props.appStore.teamSurveyStore.isCurrentUserTeamLeader || this.props.appStore.userStore.isAdmin;

		const surveyForm = (
			<React.Fragment>
				<div className="mt--area-wrap mt--fill-white">
					<div className="mt--layout">
						<div className="mt--content mt--questionnaire">
							<SurveyNotificationBar renderCantSay={canAnswerWithCantSay} />

							<PublicSurveyHelpers renderCantSay={canAnswerWithCantSay} />
							{questions.map(question => (
								<div className="question-container mt--flex-wrap" key={question.questionId}>
									<div className="question">{this.props.appStore.translationStore.translate(question.questionText)}</div>
									<div className="question-values">
										<div className="question-range">
											<input
												type="range"
												min="1"
												max="10"
												className={`${this.extendClassName("slider", question)} ${this.props.appStore.translationStore.isRTL ? "flip" : null}`}
												name={question.questionId}
												onMouseDown={e => {
													if (e.buttons === 1 && question.untouched) {
														this.handleRangeChange(question.questionId, 5);
													}
												}}
												onKeyDown={e => {
													if (question.untouched && e.key === "ArrowLeft") {
														this.handleRangeChange(question.questionId, 5);
														e.preventDefault();
													}
												}}
												onTouchStart={() => {
													if (question.untouched) {
														this.handleRangeChange(question.questionId, 5);
													}
												}}
												onMouseUp={() => this.saveAnswer(question.questionId)}
												onKeyUp={() => this.saveAnswer(question.questionId)}
												onTouchEnd={() => this.saveAnswer(question.questionId)}
												onChange={e => this.handleRangeChange(question.questionId, parseInt(e.target.value, 10))}
												value={question.value ?? this.DEFAULT_VALUE}
												disabled={question.cannotAnswer}
											/>
										</div>
										<div className={this.extendClassName("ticks", question)}>
											{this.teamSurveyStore.getHeaderValues.map(e => (
												<div key={e} className="tick"></div>
											))}
										</div>
										<div
											className="range-fill"
											ref={ref => {
												this[question.questionId] = ref;
											}}
											style={{width: this.calculateWidthInPercentage(question.value)}}
										></div>
										<div className={this.extendClassName("range-box", question)}></div>
									</div>
									{this.teamSurveyStore.canAnswerWithCantSay && (
										<div className="question-checkbox">
											<input
												type="checkbox"
												className="mt--chkbox"
												onChange={e => this.handleCheckboxChange(question.questionId, e)}
												name={question.questionId}
												checked={question.cannotAnswer}
											/>
										</div>
									)}
								</div>
							))}
							<PublicSurveyHelpers renderStatements={false} renderCantSay={canAnswerWithCantSay} />
						</div>
					</div>
				</div>

				<div className="mt--area-wrap mt--fill-white">
					<div className="mt--layout">
						<div className="mt--content mt--py-0">
							<div className="mt--info-area">
								<div>
									<div className="mt--txt mt--flex mt--gap-05 mt--align-center">
										<FontAwesomeIcon icon={["far", "info-circle"]} className="tint-orange" />
										<span>
											{this.teamSurveyStore.untouchedQuestionCount <= 1 ? (
												<Trans
													i18nKey="This diagnostic can be submitted once all statements are answered. <b>{{count}}</b> question remain."
													values={{count: this.teamSurveyStore.untouchedQuestionCount}}
												/>
											) : (
												<Trans
													i18nKey="This diagnostic can be submitted once all statements are answered. <b>{{count}}</b> questions remain."
													values={{count: this.teamSurveyStore.untouchedQuestionCount}}
												/>
											)}
										</span>
									</div>
									<div className="mt--txt"></div>
								</div>
								<div>
									<button
										className={"mt--btn btn-med" + (!canSubmitSurvey ? " disabled" : "")}
										disabled={!canSubmitSurvey}
										onClick={this.onSubmitDiagnostic}
										ref={this.submitButtonRef}
									>
										<div className="mt--ico">
											<FontAwesomeIcon icon={["far", "arrow-from-bottom"]} />
										</div>
										<div className="btn-txt">{this.props.appStore.translationStore.translate("Submit Diagnostic")}</div>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		);

		return (
			<React.Fragment>
				<PublicPageHeader
					title={this.props.appStore.translationStore.translate("Team Diagnostic")}
					navigationItems={getSurveyNavigationItems({
						translation: this.props.appStore.translationStore,
						teamId,
						pSurveyId,
						isTeamLeaderOrAdmin,
					})}
				/>
				<div className="mt--min-height">
					{this.state.canEdit ? (
						surveyForm
					) : (
						<div className="mt--area-wrap mt--fill-white">
							<div className="mt--layout">
								<div className="mt--content mt--questionnaire">
									<PublicSurveyStatus isDiagnosticSubmitted={isDiagnosticSubmitted} completionDate={this.teamSurveyStore.completionDate} />
								</div>
							</div>
						</div>
					)}
				</div>
			</React.Fragment>
		);
	}
}

export default withRouter(PublicSurvey);
