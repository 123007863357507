import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {injectAppStore} from "../../../common/app.store.consumer";
import {AppStore} from "../../../stores/app.store";
import saveAs from "file-saver";
import moment from "moment";

interface IProps {
	appStore?: AppStore;
}

const PublicSurveyResultsRecommendationsPdfDownload: React.FC<IProps> = injectAppStore()(({appStore}) => {
	const downloadLink = appStore.surveyResultsStore.recommendationsPdf.recommendationsPdfDownloadLink;

	const openDownloadLink = async () => {
		const link = appStore.surveyResultsStore.recommendationsPdf.recommendationsPdfDownloadLinks[appStore.translationStore.getLanguageCode];
		const data = await window.fetch(link).then(response => response.blob())
		saveAs(data, `${appStore.surveyResultsStore.teamName.toLowerCase().replace(" ", "_")}_${moment().format("d-M-y_h-m")}.pdf`);
	};

	return (
		<div>
			<button
				disabled={!downloadLink}
				onClick={openDownloadLink}
				className={!downloadLink ? "mt--btn btn-cta btn-spinner" : "mt--btn btn-cta"}
				style={{maxWidth: "none"}}
			>
				<div className="mt--align-self-center mt--ico">
					{!downloadLink ? <FontAwesomeIcon icon={["fas", "spinner-third"]} spin /> : <FontAwesomeIcon icon={["fas", "arrow-down"]} />}
				</div>
				<div>
					<div className="txt-main">{!downloadLink ? appStore.translationStore.translate("Processing") : appStore.translationStore.translate("Recommendations")}</div>
					<div className="txt-sub">{!downloadLink ? appStore.translationStore.translate("Results are being processed") : appStore.translationStore.translate("Download PDF")}</div>
				</div>
			</button>
		</div>
	);
});

export default PublicSurveyResultsRecommendationsPdfDownload;
